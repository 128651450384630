import './style/main.scss'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import router from '@/router'
import App from './App.vue'
!window.Promise && (window.Promise = Promise)

Vue.use(VueRouter)
Vue.use(Vuex)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
