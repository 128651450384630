import VueRouter from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '../page/IndexPage.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "home" */ '../page/HomePage.vue')
      },
      {
        path: 'contact',
        component: () => import(/* webpackChunkName: "home" */ '../page/ContactPage.vue')
      }
    ]
  }
]

export default new VueRouter({
  mode: 'history',
  routes
})
